<ng-container>
    <div class="form-section">
        <label for="nome" i18n>Nome</label>
        <input type="text" name="nome" [(ngModel)]="usuario.nome" required maxlength="50" #nome="ngModel" [class.danger]="!nome.valid && !nome.pristine" id="usuarioNome">
        <div class="alert danger" *ngIf="!nome.valid && !nome.pristine">
            <div *ngIf="nome.errors.maxlength" i18n>Máximo de 50 caracteres</div>
            <div *ngIf="nome.errors.required" i18n>Este campo é obrigatório</div>
        </div>
    </div>
    <div class="form-section">
        <label for="email" i18n>Email</label>
        <input id="usuarioEmail" type="email" name="email" [(ngModel)]="usuario.email" email required maxlength="50" #email="ngModel" [class.danger]="email.invalid && !email.pristine" [asyncEmail]='oldUser'>
        <div class="alert danger" *ngIf="email.invalid && !email.pristine" id="usuarioEmail">
            <div *ngIf="email.errors?.maxlength" i18n>Máximo de 50 caracteres</div>
            <div *ngIf="email.errors?.required" i18n>Este campo é obrigatório</div>
            <div *ngIf="email.errors?.email" i18n>Este e-mail é inválido</div>
            <div *ngIf="email.errors?.asyncEmail" i18n>Este e-mail já está em uso</div>
        </div>
        <div class="alert warning" *ngIf="email.pending && !email.pristine" i18n>
            Verificando email...
        </div>
        <div class="alert success" *ngIf="email.valid && !email.pristine" i18n>
            Email disponível
        </div>
    </div>
    <div class="form-section">
        <label for="telefone" i18n>Telefone</label>
        <input type="tel" name="telefone" [(ngModel)]="usuario.telefone" maxlength="20" mask="(00)0000-00009" #telefone="ngModel" [class.danger]="!telefone.valid && !telefone.pristine" id="usuarioTelefone">
        <div class="alert danger" *ngIf="!telefone.valid && !telefone.pristine">
            <div *ngIf="telefone.errors.maxlength" i18n>Máximo de 20 caracteres</div>
            <div *ngIf="telefone.errors['Mask error']" i18n>Telefone inválido</div>
        </div>
    </div>
    <div class="form-section password-container" *ngIf="usuario.senha != undefined">
        <label for="senha" i18n>Senha</label>
        <input [attr.type]="passwordToggled ? 'text' : 'password'" name="senha" [(ngModel)]="usuario.senha" required minlength="8" [pattern]="regex" #senha="ngModel" (change)="validatePasswordConfirmation()" [class.danger]="!senha.valid && !senha.pristine" id="usuarioSenha">
        <span class="password-icon" (click)="togglePassword()">
            <i class="icon size-2 clickable">
                <svg viewBox="0 0 24 24">
                    <use [attr.xlink:href]="passwordToggled ? 'assets/img/icon-password-off.svg#icon' : 'assets/img/icon-password-on.svg#icon'"></use>
                </svg>
            </i>
        </span>
        <div class="alert danger" *ngIf="!senha.valid && !senha.pristine">
            <div *ngIf="senha.errors.minlength" i18n>Mínimo de 8 caracteres</div>
            <div *ngIf="senha.errors.pattern" i18n>A senha deve possuir pelo menos uma letra e um número</div>
            <div *ngIf="senha.errors.required" i18n>Este campo é obrigatório</div>
        </div>
    </div>
    <div class="form-section" *ngIf="!usuario.id">
        <label for="confirmacaoSenha" i18n>Confirmação da senha</label>
        <input [attr.type]="passwordToggled ? 'text' : 'password'" name="confirmacaoSenha" [(ngModel)]="usuario.confirmacaoSenha" required minlength="8" [pattern]="regex" #confirmacaoSenha="ngModel" mustMatch="senha" [class.danger]="!confirmacaoSenha.valid && !confirmacaoSenha.pristine" id="usuarioConfirmacaoSenha">
        <span class="password-icon" (click)="togglePassword()">
            <i class="icon size-2 clickable">
                <svg viewBox="0 0 24 24">
                    <use [attr.xlink:href]="passwordToggled ? 'assets/img/icon-password-off.svg#icon' : 'assets/img/icon-password-on.svg#icon'"></use>
                </svg>
            </i>
        </span>
        <div class="alert danger" *ngIf="!confirmacaoSenha.valid && !confirmacaoSenha.pristine">
            <div *ngIf="confirmacaoSenha.errors?.doesntMatch" i18n>Confirmação da senha não confere com a senha</div>
        </div>
    </div>
</ng-container>
