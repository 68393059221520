<div class="ml mr">
    <h1 i18n>Técnicos</h1>
    <button class="btn btn-primary" type="button" name="button" routerLink="/tecnicos/cadastrar" i18n id="novo-tecnico">Novo Técnico</button>

    <div class="flex-container mt">
        <ng-container *ngFor="let usuario of tecnicos">
            <div class="bordered mr card elevation-1" *ngIf="usuario.id != auth.user.id">
                <h3>
                    <i class="icon">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-person.svg#icon"></use>
                        </svg>
                    </i>
                    <span class="mr">
                        {{usuario.nome}}
                    </span>
                </h3>
                <p>{{usuario.email}}</p>
                <p *ngIf="usuario.telefone.length > 0">{{usuario.telefone}}</p>
                <p *ngIf="usuario.telefone.length == 0" i18n>Nenhum telefone cadastrado</p>
                <div class="btn-container">
                    <button class="btn btn-outline outline-danger btnExcluir" type="button" name="button" (click)="excluir(usuario)" *ngIf="!usuario.administrador" i18n >Excluir</button>
                    <button class="btn btn-primary ml btnEditar" type="button" name="button" routerLink="/tecnicos/{{usuario.id}}/editar" i18n>Editar</button>
                </div>
            </div>
        </ng-container>
    </div>

</div>
