import { Component, ElementRef, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { HelpService } from "@app/services/help.service";

import { RecursosApiService } from "@app/services/recursos-api.service";

@Component({
    selector: "app-recursos-upload-page",
    templateUrl: "./recursos-upload-page.component.html",
    styleUrls: ["./recursos-upload-page.component.scss"],
})
export class RecursosUploadPageComponent {
    public submitting = false;

    private image: File;
    @ViewChild('color_type') private rgbSelect: ElementRef;

    constructor(
        public recursosApi: RecursosApiService,
        private router: Router,
        private titleService: Title,
        private help: HelpService
    ) {
        this.help.local = "recursos";
        this.titleService.setTitle(
            $localize`Adicionar recurso - Thoreb Itinerário`
        );
    }

    change(value: string | File) {
        if (typeof (value) == 'string') {
            throw new Error("Valor inválido?????");
        }

        this.image = value;
    }

    async submit() {
        try {
            this.submitting = true;

            let image_type = this?.rgbSelect?.nativeElement?.value;

            await this.recursosApi.upload(this.image, image_type);
            this.router.navigate(["recursos"]);
        } catch (e) {
            console.error(e);
            this.submitting = false;
        }
    }
}
