<div class="ml mt mr">
    <h1 i18n>Upload de imagem para recurso</h1>
    <form class="form-section">
        <h2 i18n>Tipo de recurso</h2>

        <select #color_type name="color_type">
            <option value="rgb">RGB</option>
            <option value="mono">Monocromático</option>
        </select>

        <app-file-input mode="arrayBuffer" (change)="change($event)" />

        <button
            id="salvar-recurso"
            class="btn btn-success"
            type="submit"
            name="button"
            (click)="submit()"
            i18n
        >
            Cadastrar recurso
        </button>
    </form>
</div>
