
<h1 i18n>Perfil</h1>
<div class="bordered" *ngIf="empresa">
    <div class="flex-container">
        <div class="flex-row-left">
            <h2>
                <i class="icon">
                    <svg viewBox="0 0 24 24">
                        <use xlink:href="assets/img/icon-profile.svg#icon"></use>
                    </svg>
                </i>
                <span>
                    {{logado.nome}}
                </span>
            </h2>
            <p>
                <strong i18n>E-mail:</strong> <span> {{logado.email}}</span>
            </p>
            <p *ngIf="logado.telefone.length > 0">
                <strong i18n>Telefone:</strong> <span> {{logado.telefone}}</span>
            </p>
            <p *ngIf="logado.telefone.length == 0">
                <strong i18n>Nenhum telefone cadastrado para este usuário</strong>
            </p>

            <button class="btn btn-outline" type="button" name="button" routerLink="/usuarios/senha" i18n id="editarPerfilCredenciais">Alterar minha senha</button>
            <button class="btn btn-primary ml" type="button" name="button" routerLink="/usuarios/{{logado.id}}/editar" *ngIf="auth.user.administrador" i18n id="editarPerfil">Editar</button>
        </div>
        <div class="flex-row-right">
            <h2 i18n>Aplicativo</h2>
            <!-- {{auth.user | json}} -->
            <qrcode class="qrcode" [qrdata]="qrContent" [width]="154" style="pointer-events: none"></qrcode>
            <small i18n>Escaneie este código com o seu aplicativo Thoreb para ter acesso aos projetos e para transferir para o painel.</small>
        </div>
    </div>
    <hr>
    <div class="flex-container">
        <div class="">
            <h2>
                <i class="icon">
                    <svg viewBox="0 0 24 24">
                        <use xlink:href="assets/img/icon-enterprise.svg#icon"></use>
                    </svg>
                </i>
                <span i18n>
                    Empresa {{empresa.nome}}
                </span>
            </h2>
            <p>
                <strong i18n>CNPJ:</strong> <span> {{empresa.cnpj}}</span>
            </p>
            <button class="btn btn-primary" type="button" name="button" *ngIf="auth.user.administrador" [disabled]="empresa.usuarios.length >= 10" routerLink="/usuarios/cadastrar" i18n id="novo-usuario">
                Adicionar usuário
            </button>
        </div>
    </div>
    <h3 i18n>Outros membros da empresa:</h3>
    <!-- {{empresa | json}} -->
    <div class="flex-container mt">
        <ng-container *ngFor="let usuario of empresa.usuarios">
            <div class="bordered mr card elevation-1 usuario-item" *ngIf="usuario.id != auth.user.id">
                <h3>
                    <i class="icon">
                        <svg viewBox="0 0 24 24">
                            <use xlink:href="assets/img/icon-person.svg#icon"></use>
                        </svg>
                    </i>
                    <span class="mr">
                        {{usuario.nome}}
                    </span>
                </h3>
                <p>{{usuario.email}}</p>
                <p *ngIf="usuario.telefone.length > 0">{{usuario.telefone}}</p>
                <p *ngIf="usuario.telefone.length == 0" i18n>Nenhum telefone cadastrado</p>
                <div class="btn-container" *ngIf="auth.user.administrador">
                    <button class="btn btn-outline outline-danger btnExcluir" type="button" name="button" (click)="excluirUsuario(usuario)" *ngIf="!usuario.administrador" i18n>Excluir</button>
                    <button class="btn btn-primary ml btnExcluir btnEditar" type="button" name="button" routerLink="/usuarios/{{usuario.id}}/editar" i18n>Editar</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
