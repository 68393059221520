<div class="ml mr">
    <h1 i18n>Editar Técnico</h1>
    <!-- {{tecnico | json}} -->
    <form #tecnicoForm="ngForm">

        <app-tecnico-form [tecnico]="tecnico" *ngIf="tecnico != undefined"></app-tecnico-form>

        <button class="btn btn-success btnSalvar" type="submit" name="button" (click)="salvar(tecnico)" [disabled]="!tecnicoForm.form.valid" i18n>Editar</button>
    </form>
</div>
